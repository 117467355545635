import Link from 'next/link';
import Image from 'next/image';
import { FC, useState, memo, useRef } from 'react';
import { Slide as SlideType } from './constants';
import styles from './carousel.module.scss';

interface SlideProps extends SlideType {
  idx: number;
}

export const Slide: FC<SlideProps> = memo(({ path, alt, href, idx, mediaFormat }) => {
  const [isReady, setIsReady] = useState(false);
  const myLoader = ({ src }: { src: string }) => src;
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const banners = mediaFormat?.includes('video') ? (
    <video
      ref={videoRef}
      src={path}
      autoPlay
      loop
      muted
      onLoadedData={() => setIsReady(true)}
      className={styles.image__video}
    ></video>) : (
    <Image
      fill
      src={path}
      loader={myLoader}
      alt={alt}
      priority={idx === 0 || idx === 1}
      onLoad={() => setIsReady(true)}
      className={styles.image}
    />);

  return (
    <Link href={href || ''} className={styles.slide}>
      {!isReady && <div className={styles.skeleton} />}
      {banners}
    </Link>
  );
});

Slide.displayName = 'Slide';
