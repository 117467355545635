import { GetServerSidePropsContext } from 'next';
import { useEffect } from 'react';
import MainPage from '@/modules/main/main-page';
import { getProps } from '@/modules/main/server-side-props';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { changeCurrIndex } from '@/store/slice/app.slice';
import { TariffMobileEntity } from '@/store/api/tariff-mobile/tariff-mobile.type';
import { TariffHomeInternetCategoryEntity } from '@/store/api/tariff-home-internet-category/tariff-home-internet-catogory.type';
import { MainPageEntity } from '@/store/api/main-page/main-page.type';
import { BeautifulNumber, NumberCategory } from '@/store/api/beautiful-numbers/beautiful-numbers.type';
import { ArticleEntity } from '@/store/api/article/article.type';

interface Props {
  tariffsMobile: TariffMobileEntity;
  tariffHomeInternetCategories: TariffHomeInternetCategoryEntity;
  mainPage: MainPageEntity;
  numbers: Record<string, BeautifulNumber[]>;
  numberCategories: NumberCategory[];
  articles: ArticleEntity;
}

export default function Index({
  tariffsMobile,
  tariffHomeInternetCategories,
  mainPage,
  numbers,
  numberCategories,
  articles,
}: Props) {
  const currIndex = useAppSelector((state) => state.app.currIndex);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeCurrIndex(currIndex + 1));
  }, []);

  return (
    <MainPage
      tariffsMobile={tariffsMobile}
      tariffHomeInternetCategories={tariffHomeInternetCategories}
      mainPage={mainPage}
      numbers={numbers}
      numberCategories={numberCategories}
      articles={articles}
    />
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return getProps(context);
}
