import { FC, HTMLAttributes, useState, useEffect, ChangeEvent } from 'react';
import Link from 'next/link';
import { TextInput,  Button } from '@mantine/core';
import { LoadingOverlay } from '@mantine/core';
import cn from 'classnames';
import { usePreCrmCreateTicketMutation } from '@/store/api/ticket/ticket.api';
import { YM } from '@/shared/constants/yandex-metrika';
import { reachGoal } from '@/shared/lib/ym';
import { COLORS } from '@/shared/constants/colors';
import { Path } from '@/shared/constants/links';
import { isValidAddress } from '@/shared/lib/validation';
import { PhoneInput } from '@/shared/ui-kit';
import { ChevronRight } from '@/shared/assets/icons';
import { ResultModal } from '@/modules/tariff/shared/result-modal/result-modal';
import { HOME_INTERNET__CONSTANTS } from './constants';
import styles from './home-internet.module.scss';

export const HomeInternet: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [createTicket, { isError, error, data, isSuccess, isLoading }] = usePreCrmCreateTicketMutation();
  const [showModal, setShowModal] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  // const [possible, setPossible] = useState(false);

  const onPhoneChange = (val?: string) => {
    (val || val === '') && setPhone(val);
  };

  const onAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    if (isValidAddress(currentValue)) {
      setAddress(currentValue);
    }
  };

  const createTicketRequest = async () => {
    await createTicket({
      abonent_type_id: 3,
      address: address,
      bil_trpl_id: 1,
      building_type_id: 1,
      contact_name: 'Сайт Волны - Главная страница',
      contact_phone: phone,
      iptv: 0,
      msisdn_referer: '',
      ticket_type_id: 1,
      bil_service_id: [],
    }).unwrap();
    reachGoal(YM.form_send_SHPD_main);
  };

  useEffect(() => {
    if (isError) {
      setResultMessage('Ошибка');
      setInfoMessage('К сожалению, произошла ошибка. Попробуйте еще раз позже.');
      setShowModal(true);
    }

    if (isSuccess && data) {
      setAddress('');
      setPhone('');
      setResultMessage('Заявка отправлена');
      setInfoMessage('Оператор свяжется с вами в течение 15 минут для уточнения даты подключения домашнего интернета.');
      setShowModal(true);
    }
  }, [isError, error, data, isSuccess]);

  const buttonLabel = isLoading ? (
    <div className={styles.loading}>
      <LoadingOverlay visible />
    </div>
  ) : (
    `${HOME_INTERNET__CONSTANTS.BUTTON_POSSIBLE}`
  );

  return (
    <div className={cn(styles.container, className && className)} {...props}>
      <div className={styles.wrapper}>
        <Link href={Path.tariffHomeInternet.root} className={styles.link}>
          <span className={styles.link__text}>{HOME_INTERNET__CONSTANTS.TITLE}</span>
          <ChevronRight className={styles.link__chevron} color={COLORS.base[0]} />
        </Link>
        <TextInput
          maxLength={100}
          label={HOME_INTERNET__CONSTANTS.ADDRESS_LABEL}
          placeholder={HOME_INTERNET__CONSTANTS.ADDRESS_PLACEHOLDER}
          classNames={{
            root: styles.input__root,
            input: styles.input__input,
            label: styles.input__label,
            rightSection: styles.input__right_section,
          }}
          value={address}
          onChange={onAddressChange}
          // rightSection={address && <RightSection possible={possible} />}
          // rightSectionWidth={isMobile ? undefined : address && (possible ? 197 : 235)}
        />
        {/* {address && isMobile && <RightSection possible={possible} />}
        {address && !possible && (
          <>
            <div className={styles.description}>
              {HOME_INTERNET__CONSTANTS.ADDRESS_DESCRIPTION_PART1} <br />
              {HOME_INTERNET__CONSTANTS.ADDRESS_DESCRIPTION_PART2}
            </div>

            <PhoneInput darkBackground onChange={(b) => onPhoneChange(b)} />
          </>
        )} */}
        <PhoneInput
          darkBackground
          onChange={(b) => onPhoneChange(b)}
          label={HOME_INTERNET__CONSTANTS.PHONE_LABEL}
          value={phone}
        />
      </div>
      <div className={styles.footer}>
        <Button
          disabled={address === '' || phone.length !== 10}
          onClick={createTicketRequest}
          classNames={{
            root: styles.button__root,
          }}
        >
          {buttonLabel}
        </Button>
        {/* {possible && address && <div className={styles.price}>{HOME_INTERNET__CONSTANTS.PRICE}</div>} */}
      </div>
      <ResultModal opened={showModal} close={() => setShowModal(false)} title={resultMessage} info={infoMessage} />
    </div>
  );
};
