import { memo } from 'react';
import { Slide as SlideType, carouselMessages } from './constants';
import styles from './carousel.module.scss';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Slide } from './slide';

export const Carousel = memo(({ images }: { images: SlideType[] | undefined }) => {
  const { isMobile } = useMediaQuery();

  const isImagesNotEmpty = images && images.length > 0;

  if (!isImagesNotEmpty) {
    return null;
  }

  return (
    <Swiper
      modules={[Navigation, Pagination, A11y, Autoplay]}
      slidesPerView={1}
      navigation={!isMobile}
      pagination={{ clickable: true }}
      loop
      autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
      className={styles.swiper}
      a11y={carouselMessages}
    >
      {images?.map((slide, idx,) => (
        <SwiperSlide key={idx}>
          <Slide idx={idx} {...slide} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
});

Carousel.displayName = 'Carousel';
