import { ChevronRight } from '@/shared/assets/icons';
import Link from 'next/link';
import { Tabs } from '@mantine/core';
import { REPLENISHMENT_CONSTANTS } from './constants';
import styles from './replenishment.module.scss';
import { ReplenishmentContent } from './content';
import { FC, useState } from 'react';
import { ReplenishmentProps } from './replenishment.types';
import cn from 'classnames';
import { ResultModal } from '@/modules/tariff/shared/result-modal/result-modal';
import { Path } from '@/shared/constants/links';
import { COLORS } from '@/shared/constants/colors';

export const Replenishment: FC<ReplenishmentProps> = ({ lightMode, className, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <div className={cn(styles.container, lightMode && styles.light, className && className)} {...props}>
        {!lightMode && (
          <Link href={Path.replenishment.root} className={styles.link}>
            <span className={styles.link__text}>{REPLENISHMENT_CONSTANTS.TITLE}</span>
            <ChevronRight className={styles.link__chevron} color={COLORS.base[0]} />
          </Link>
        )}

        <Tabs
          classNames={{
            root: styles.tabs__root,
            tabsList: styles.tabs__tabsList,
            tab: styles.tabs__tab,
            panel: styles.tabs__panel,
          }}
          defaultValue={REPLENISHMENT_CONSTANTS.MOBILE_CONNECTION}
        >
          <Tabs.List>
            <Tabs.Tab value={REPLENISHMENT_CONSTANTS.MOBILE_CONNECTION}>
              {REPLENISHMENT_CONSTANTS.MOBILE_CONNECTION}
            </Tabs.Tab>
            <Tabs.Tab value={REPLENISHMENT_CONSTANTS.HOME_INTERNET}>{REPLENISHMENT_CONSTANTS.HOME_INTERNET}</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={REPLENISHMENT_CONSTANTS.MOBILE_CONNECTION}>
            <ReplenishmentContent lightMode={lightMode} openModal={openModal} mobile />
          </Tabs.Panel>

          <Tabs.Panel value={REPLENISHMENT_CONSTANTS.HOME_INTERNET}>
            <ReplenishmentContent openModal={openModal} lightMode={lightMode} />
          </Tabs.Panel>
        </Tabs>
      </div>
      <ResultModal
        opened={showModal}
        close={handleCloseModal}
        title='Ошибка'
        info='К сожалению, не удалось пополнить счет. Попробуйте еще раз.'
      />
    </>
  );
};
