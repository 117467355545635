import { ArticleCardItem } from '@/shared/ui-kit/news-card/news-card.types';
import { ArticleEntity } from '@/store/api/article/article.type';
import { format } from 'date-fns';

export const articlesResponseToArticleCarouselMapper = (resp: ArticleEntity): ArticleCardItem[] => {
  return resp.data.map((item) => ({
    id: item.id,
    src: item.attributes.preview_picture?.data?.attributes.url,
    alternativeText: item.attributes.main_picture?.data?.attributes.alternativeText || '',
    title: item.attributes.title,
    date: format(new Date(item.attributes.publishDate), 'dd.MM.yy'),
    tag: item.attributes.tag,
    publishDate: format(new Date(item.attributes.publishDate), 'yyyy-MM-dd HH:mm:ss'),
    mediaFormat: item.attributes.preview_picture.data.attributes.mime,
  }));
};
