import { ArrowRight } from '@/shared/assets/svg';
import styles from './news-header.module.scss';
import { Pills } from '@/shared/ui-kit';
import Link from 'next/link';
import { Path } from '@/shared/constants/links';

interface NewsHeaderProps {
  onChangeTag: (value: string) => void;
  tags: string[];
  activeTag: string;
}

export const NewsHeader: React.FC<NewsHeaderProps> = ({ onChangeTag, tags, activeTag }) => (
  <div className={styles.wrapper}>
    <Link className={styles.link} href={Path.news}>
      <span>Новости и акции</span>
      <ArrowRight />
    </Link>
    <Pills data={tags} value={activeTag} onChange={onChangeTag} />
  </div>
);
